import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../actions/plan_actions';
import * as marketActions from '../../actions/market_actions';
import * as currentPlanActions from '../../actions/current_plan_actions';
import * as progress_actions from '../../actions/progress_actions';
import * as userActions from '../../actions/user_actions';
import './PlanViewer.css';
import '../PlanCard/PlanCard';
import PlanCard from '../PlanCard/PlanCard';
import { dummyNewCard } from '../../dummyData/dummyNewCard';
import PlanCreator from './PlanCreator/PlanCreator';
import {Icon} from 'react-materialize'
import { toast } from "react-toastify";
import ToastMessage from '../ToastMessage/ToastMessage';
import ToastDoneMessage from '../ToastMessage/ToastDoneMessage';
import Loading from '../Summary/Loading/Loading';


// This component processes the data sent back from the Plan Creator Component. The Fetch request will be 
// sent to the back end and create a notification to tell the user the opitmization is in process
// Once the request is complete it will send the 'toast' and update to say the optimization is complete or 
// has errored out. Once the new plan is created it sends the plan to the redux store and is now available globally

class PlanViewer extends Component {

    state = {
        createPlan: false,
        loading: false,
        isLoading: true
    }

    toggleCreator = (bool) => {
        this.setState({
          createPlan: bool
        })
        if(bool) setTimeout(() => this.scrollToBottom(),50);
    }

    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    }

    toastId = null;

    extraClass = ''


    // starts toaster view after the user confirms their plan creation preferences
    notify = () => this.toastId = toast(<ToastMessage />, { autoClose: false, hideProgressBar: true, position: toast.POSITION.BOTTOM_CENTER });

    // updates the user that the plan has been processed and sent to the front end
    update = () => toast.update(this.toastId, { render: <ToastDoneMessage />,  autoClose: 2000 });
    
    createPlan = (newplan) => {
        newplan.market = this.props.market.code ? this.props.market.code : 'Florida_NW'
        this.setState({
          loading: true
        })
        //5 plans maximum
        if (this.props.plans.length < 7) {
          this.props.progress_actions.startOptimize({ planName: newplan.name, inProgress: true })
          this.extraClass = 'creator-mover'
          setTimeout(() => {
            this.setState({
              createPlan: false
            })
          }, 200)
          this.notify()        
          //fetch("https://qa.optimus.realogydev.com/get-optimized-plan", {
          fetch("https://qa-optimus.eapdenonprd.realogydev.com/get-optimized-plan", {
            method: 'POST',
            mode: 'cors',
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify(newplan)
          })
          .then( res => res.json())
          .then( data => {
            this.update()
            this.props.progress_actions.endOptimize({ planName: newplan.name, inProgress: false })
            this.toggleCreator()
            this.extraClass = '';
            this.setState({
              loading: false
            })
            this.constructPlan(data.result, newplan)
          })
          .catch(err => {
            this.setState({
              loading: false
            })
            this.props.progress_actions.endOptimize({ planName: null, inProgress: false })
            toast.update(this.toastId, { render: 'Optimization Failed',  autoClose: 4000 })
          })
        } else {
          this.setState({
              loading: false
            })
          alert('Too many plans are being created for this market. Please remove old plans before creating more.')
        }
    }

    constructPlan = (data, newplan) => {
      // Copy scaffolded plan and assign initial data
      let planCopy = {...dummyNewCard,
                          tiers: [...dummyNewCard.tiers]
                      }

        planCopy.title = newplan.name
        planCopy.segments = newplan.segments
        planCopy.recruitingTarget = newplan.recruitingTarget
        planCopy.rationale = newplan.rationale
      
      // Incorporate optimizer response into the card
      this.setBasePlanData(planCopy, data);
    }

    constructDefaultPlans = (data) => {
      // Copy scaffolded plan and assign initial data
      let planCopy = {...dummyNewCard,
                          tiers: [...dummyNewCard.tiers]
                      }
        planCopy.title = (data.name !== 'freedom') ? 'Accelerator Plan' : 'Freedom Plan'
        planCopy.segments = null
        planCopy.rationale = (data.name !== 'freedom') ? 'The Accelerator Plan is designed for agents with significant intra-year growth expected' : 'The Freedom Plan is for high- and low-producing agents who expect low intra-year growth';

      // Incorporate optimizer response into the card
      this.setBasePlanData(planCopy, data);
    }

    setBasePlanData = (planCopy, data) => {
    
      if (data) {
        planCopy.structure = data.structure
        planCopy.annualFee = Math.round(data.annual_fee)
        planCopy.transactionFee =  Math.round(data.transaction_fee)
        planCopy.cap = data.cap
        planCopy.tiers = planCopy.tiers.slice(0, Object.keys(data['level_rate']).length)
        planCopy.annual_fee = parseInt(data.annual_fee)
        planCopy.transaction_fee = parseInt(data.transaction_fee)
        planCopy.monthly_fee = parseInt(data.monthly_fee)
        planCopy.franchise_fee = data.franchise_fee
        planCopy.rp_final = parseFloat(data['rp_final'])
        planCopy.recruiting_premium_total = parseFloat(data['recruiting_premium_total'])

        //NOTE: RECRUITING PREMIUM IS BEING SUBTRACTED HERE FROM THE TIERS IN ORDER TO SEPARATE IT OUT.
        planCopy.tiers = planCopy.tiers.map((tier, idx) => {
          return {
            tier : tier.tier,
            max : idx == planCopy.tiers.length-1 ? null : data['level_rate'][idx][0],
            min : idx == 0 ? 0 : data['level_rate'][idx - 1][0] + 1,
            gci : tier.gci,
            tier_rp : planCopy.rp_final,
            split : (parseFloat(data['level_rate'][idx][1]) - planCopy.rp_final)
            // split: parseFloat(data['level_rate'][idx][1])

          }
        })
        //END NOTE
        if (data.gci_to_cap) {
          planCopy.gci_to_cap = Object.values(data.gci_to_cap).map((tier, idx) => {
            return tier
          })
        }
      }

      this.props.planAction.addNewPlan(planCopy)
    }

    removePlan = (index) => {
        this.props.planAction.removePlan(index)
    }

    componentDidMount() {

      //reload if there is no market data
      if (this.props.market.agentSplit == undefined) {
        this.props.history.push('/')
      }
      
      const market = this.props.market.code ?  this.props.market.code : 'Atlanta'

      if (this.props.plans.length < 2) {
        console.log(this.props.user)
      //  let myHeaders = new Headers([
      //       ['Content-Type', 'application/json',
      //         'username', this.props.user.username,
      //         'token', this.props.user.token]
      //   ]);
      

        fetch("https://qa-optimus.eapdenonprd.realogydev.com/get-default-plans?market=" + market, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
            "user": this.props.user.username,
            "token": this.props.user.token
          }
        })
        .then( res => {
          // console.log(res)
          if (res.ok) {
            return res.json()
          } else {
            throw new Error("Couldn't fetch default plans!")
          }
        })
        .then( data => {
          data.forEach(plan => {
            this.constructDefaultPlans(plan)
          })
          this.setState({
            isLoading: false
          })
        })
        .catch( error => {
          console.log(error)
        })
      } else {
        this.setState({
          isLoading: false
        })
      }

    }


    render() {
      // console.log(this.props.market)
      const Cards = this.props.plans.map((item, index) => {
        return <PlanCard key={index} planId={index} remove={this.removePlan} {...item}></PlanCard>
      })
      const Creator = this.state.createPlan ?  <PlanCreator extraClass={this.extraClass} loading={this.state.loading} createPlan={this.createPlan} cancel={this.toggleCreator} /> : '';

      return (
          <div className="plan-viewer">
               <Loading isLoading={this.state.isLoading} />
              <button onClick={ () => { this.props.history.push('/summary')}} className='btn waves-effect nav-button-text space-right'>
                  Continue
              </button>
              <button onClick={ () => {this.props.history.push('/market-overview')}} className='btn waves-effect nav-button-text space-right'>
                  Go Back
              </button>

              <div className="plan-viewer-container">
                  <h1 className="plan-create-title"><span className="plan-create-light">Create Plans for</span> {this.props.market.name}</h1>
                  <p className="plan-create-message">Here are recommended base plans for this market. You can modify these plans, or create up to 3 more custom plans.</p>
                  <button disabled={this.state.loading || this.state.createPlan || this.props.progress.inProgress } onClick={() => this.toggleCreator(true)} className='btn waves-effect create-btn'><Icon>add_circle_outline</Icon>Create a Plan</button>

                  {Cards}
                  {Creator}
                  
                <div ref={el => { this.el = el; }} />
              </div>
          </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        current: state.current,
        market: state.market,
        progress: state.progress,
        user: state.user
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch),
        progress_actions: bindActionCreators(progress_actions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
     }
   }
  
  export default connect(mapStateToProps, mapDispatchToProps)(PlanViewer)
