import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as PlanActions from '../../actions/plan_actions';
import * as marketActions from '../../actions/market_actions';
import * as currentPlanActions from '../../actions/current_plan_actions';
import * as userActions from '../../actions/user_actions';
import './Summary.css';
import '../PlanCard/PlanCard';
import PlanCard from '../PlanCard/PlanCard'; 
import EditPlan from './EditPlan/EditPlan';
import ImpactByPlan from './ImpactByPlan/ImpactByPlan';
import SplitLineChart from '../Charts/SplitLineChart/SplitLineChart';
import AreaChart from '../Charts/AreaChart/AreaChart';
import AreaSegmentChart from '../Charts/AreaSegmentChart/AreaSegmentChart';
import Optimality from './Optimality/Optimality';
import Loading from './Loading/Loading';
import EffectiveSplitChart from '../Charts/EffectiveSplitChart/EffectiveSplitChart';
import { CSVLink, CSVDownload } from "react-csv";
import AgentLookup from './AgentLookup/AgentLookup';


// Summary view allows the user to adjust the market impact by editing the plans and adjusting the splits, 
// levels ect... From their the user can change their recruitment target and adjust on the median agent. 

class Summary extends Component {

    state = {
        createPlan: false,
        planPayload: {},
        editPlan : false,
        impactByPlan: {},
        impactBySegment: {},
        recruits: {
          '1' : 0,
          '2' : 0,
          '3' : 0,
          '4' : 0,
        },
        editTargets: false,
        gciDistribution: [],
        loading: true,
        currentSeg: 0,
        maxChartVal: 0,
        recruitingList: ""
    }


    togglePlans = (idx) => {
      if(this.state.editPlan === false) this.props.currentAction.selectCurrent(idx)
    }

    toggleEditPlan = () => {
      this.setState({
        editPlan : !this.state.editPlan
      });
    }

    getEditedPlanValues = (editedPlan) => {
      this.getPromisedPlans(editedPlan).then(()=> {
        this.fetchMarketImpact();
        this.toggleEditPlan();
      }).then(()=> {
        console.log('reloading')
        this.props.history.push('/summary')
      })
    }

    getPromisedPlans = (editedPlan) => {
      return new Promise((resolve, reject) => {
        this.props.planAction.addNewPlan(editedPlan);
        resolve()
      })
    }

    componentDidMount() {
      //reload if there is no market data
      if (this.props.market.agentSplit == undefined) {
        this.props.history.push('/')
      }
      //set max chart value to align chart axes
      this.findMaxChartVal()
      this.fetchMarketImpact();
    }

    findMaxChartVal = () => {
      let maxChartVal = 0
      this.props.plans.forEach( plan => {
        if (plan.tiers[plan.tiers.length - 1]['min'] > maxChartVal) {
          maxChartVal = plan.tiers[plan.tiers.length - 1]['min'] 
        }
      })
      this.setState({
        maxChartVal: maxChartVal
      })
    }

    fetchRecruitmentTarget = () => {
      // console.log('FIRING')
      this.setState({
        editTargets: true
      })
      let convertedPlans = this.convertPlans()
      let bodyObj = {}
      bodyObj.plan_list = convertedPlans
      bodyObj.market = this.props.market.code
      bodyObj.recruit_target_segment = this.state.recruits
      bodyObj.plan_by_segment = this.state.planPayload.result.plan_by_segment
      bodyObj.context_by_segment = this.state.planPayload.result.context_by_segment
      fetch("https://qa-optimus.eapdenonprd.realogydev.com/get-plan-impact-with_recruit", {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          "user": this.props.user.username,
          "token": this.props.user.token
        },
        body: JSON.stringify(bodyObj)
      })
      .then( res => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then( data => {
        // console.log(data)
            this.setState({
              planPayload: this.state.planPayload,
              impactByPlan: data.result,
              impactBySegment: this.state.impactBySegment,
              context: this.state.context,
              recruits: this.state.recruits,
              editTargets: false
            })
        })
       .catch( error => {
         console.log(error)
       })
    }

    fetchMarketImpact () {
      let convertedPlans = this.convertPlans()
      let bodyObj = {}
      bodyObj.plan_list = convertedPlans
      bodyObj.market = this.props.market.code ? this.props.market.code : 'Atlanta'

      fetch("https://qa-optimus.eapdenonprd.realogydev.com/get-plan-impact-segment", {
        method: 'POST',
        mode: 'cors',
        headers: {
          "Content-Type": "application/json",
          "user": this.props.user.username,
          "token": this.props.user.token
        },
        body: JSON.stringify(bodyObj)
      })
      .then( res => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then( data => {
          let recruits = {}
          data.result.context_by_segment.recruit_per_segment.forEach((val,idx)=> {
            recruits[idx + 1] = Math.round(val)
          })

        fetch("https://qa-optimus.eapdenonprd.realogydev.com/get-gci-distribution?market=" + this.props.market.code, {
          method: 'GET',
          mode: 'cors',
          headers: {
            "Content-Type": "application/json",
            "user": this.props.user.username,
            "token": this.props.user.token
          }
        })
        .then( res => res.json())
        .then( gci => {
          this.state.loading = false
            this.setState({
              planPayload: data,
              impactBySegment: data.result.plan_by_segment,
              impactByPlan: data.result.impact_by_plan,
              effectiveSplitPlot: data.effectiveSplitPlot,
              context: data.result.context_by_segment,
              recruits: recruits,
              gciDistribution: gci,
              attrition: gci.attrition[0],
              segmentResponse: gci.segmentResponse,
              recruitingList: data.recruiting_list
            })
          })
        })
        .catch( error => {
          console.log(error)
        })
    }


    setTargets = (field, val) => {
      let rec = {...this.state.recruits}
      rec[field] = parseInt(val);
      if(rec[field] > this.state.context['Number of Agents'][(parseInt(field)-1)]) rec[field] = this.state.context['Number of Agents'][(parseInt(field)-1)]
      if(rec[field] < 1) rec[field] = 1
        this.setState({
          recruits: rec,
          planPayload: this.state.planPayload,
          impactByPlan: this.state.impactByPlan,
          impactBySegment: this.state.impactBySegment,
          context: this.state.context,
        }, () => console.log(rec))
    }

    changeSeg = (seg) => {
      this.setState({
        currentSeg: seg
      })
    }

    convertPlans = () => {
      let convertedPlans = []
      this.props.plans.forEach(plan => {
        let copy = JSON.parse(JSON.stringify(plan))
        let lvl = {}
        copy.tiers.forEach( (tier, index) => {
          if (tier.max != undefined && tier.max != "") {
            lvl[index] = [parseInt(tier.max), typeof tier.split == 'string' ? (parseInt(tier.split)/100) : tier.split ]
          }
        })
        copy.gci_to_cap = plan.gci_to_cap ? [...plan.gci_to_cap] : null
        copy.level_rate = lvl
        copy.name = plan.title
        copy.franchise_fee = parseFloat(plan.franchise_fee)
        
        convertedPlans.push(copy)
      })
      return convertedPlans
    }

    render() {
      const CurrentCard = this.props.plans.map((item, index) => {
          return <PlanCard key={index} hidePlan={this.props.currentPlan} editableCard={true} planId={index} startEdit={this.toggleEditPlan} remove={this.removePlan} {...item}></PlanCard>
      })

      const PlanHeaders = this.props.plans.map((item, idx) => {
          return ( 
          <div className={"plan-btn " + (idx === this.props.currentPlan ? 'selected' : '')}  key={idx} onClick={() => this.togglePlans(idx)}>
            <div>
              {item.title}
            </div>
          </div>
          )
      })

      return (
        <div className="summary">
        <Loading isLoading={this.state.loading} />
            <button onClick={ () => { this.props.history.push('/finalized')}} className='btn waves-effect nav-button-text space-right'>Finalize</button>
            <button onClick={ () => { this.props.history.push('/plan-viewer')}} className='btn waves-effect nav-button-text space-right'>Go Back</button>
              
            <div className="summary-container">
                <h1 className="plan-create-title"><span className="plan-create-light">Comission Plans for</span> {this.props.market.name}</h1>
                <section className='plan-headers'>
                    {PlanHeaders}
                </section>

                {!this.state.editPlan ? CurrentCard : ''}
                {this.state.editPlan ? <EditPlan plan={this.props.plans[this.props.currentPlan]} confirm={this.getEditedPlanValues} cancel={this.toggleEditPlan}/> : ''}

                <SplitLineChart maxChartVal={this.state.maxChartVal} plandata={this.props.plans} />
                <AreaChart maxChartVal={this.state.maxChartVal} gciDistribution={this.state.gciDistribution} />
                <Optimality changeSeg={this.changeSeg} segmentImpact={this.state.impactBySegment}/>
                <EffectiveSplitChart maxChartVal={this.state.maxChartVal} effectiveSplitPlot={this.state.effectiveSplitPlot} currentSeg={this.state.currentSeg} plotbandData={this.state.impactBySegment}/>
                <AreaSegmentChart maxChartVal={this.state.maxChartVal} gciDistribution={this.state.gciDistribution} segmentDistribution={this.state.segmentResponse}/>
                <ImpactByPlan attrition={this.state.attrition} numAgents={this.props.market.agentSplit} editTargets={this.state.editTargets}
                 impact={this.state.impactByPlan } setTargets={this.setTargets} recruits={this.state.recruits} 
                context={this.state.context} getTargets={this.fetchRecruitmentTarget} />
                <AgentLookup agents={this.state.recruitingList} />
                <CSVLink style={{float: 'right', padding: '25px 0px'}} filename={"recruiting-list.csv"} data={this.state.recruitingList}>Download recruiting list</CSVLink>
            </div>
        </div>
      );
    }
  }
  
  const mapStateToProps = (state) => {
    return {
        plans: state.plans,
        currentPlan: state.current,
        market: state.market,
        user: state.user
    }
  }

  const mapDispatchToProps = (dispatch) => {
    return {
        planAction: bindActionCreators(PlanActions, dispatch),
        currentAction: bindActionCreators(currentPlanActions, dispatch),
        marketAction: bindActionCreators(marketActions, dispatch),
        userActions: bindActionCreators(userActions, dispatch)
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Summary)